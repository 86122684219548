import { Controller } from "@hotwired/stimulus"
import AirDatepicker from "air-datepicker"
import localeCs from "air-datepicker/locale/cs"

export default class extends Controller {
  static targets = ["input", "datepicker", "selectedValue"]

  connect() {
    this._setupDateFormat()
    this._setupPicker()
  }

  disconnect() {
    this.picker.destroy()
  }

  clear() {
    this.picker.clear()
  }

  _setupDateFormat() {
    this.dateFormat = new Intl.DateTimeFormat("cs-Cz", {
      timeZone: "Europe/Prague",
      year: "numeric",
      month: "2-digit",
      day: "numeric"
    })
  }

  _setupPicker() {
    const startDate = this.inputTarget.value

    const selectedDates = []
    if (startDate) selectedDates.push(startDate)

    this.picker = new AirDatepicker(this.inputTarget, {
      inline: true,
      locale: localeCs,
      startDate: startDate,
      selectedDates: selectedDates,
      dateFormat: "yyyy-MM-dd",
      onSelect: ({ formattedDate }) => {
        this.inputTarget.value = formattedDate
        this._updatePreview()
        this.dispatch("change")
      }
    })

    this._updatePreview()
  }

  _updatePreview() {
    const date = this.inputTarget.valueAsDate

    if (date) {
      const value = this.dateFormat.format(date)
      this.selectedValueTarget.innerHTML = value
    } else {
      this.selectedValueTarget.innerHTML = ''
    }
  }
}
