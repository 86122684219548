import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["resetBtn"]
  static classes = ["hidden"]

  connect() {
    this.update()
  }

  update() {
    const formData = new FormData(this.element)
    const isActive = [...formData.values()].some(value => !!value)
    this.resetBtnTarget.classList.toggle(this.hiddenClass, !isActive)
  }
}
