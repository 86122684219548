import { Controller } from "@hotwired/stimulus"
import { createPopper } from "@popperjs/core"

export default class extends Controller {
  static targets = ["toggler", "content"]
  static classes = ["togglerActive", "contentInactive"]

  connect() {
    this.popper = null
  }

  disconnect() {
    this.forceHide()
  }

  toggle() {
    this._destroyPopper()

    if (!this.contentTarget.classList.toggle(this.contentInactiveClass)) {
      if (this.hasTogglerActiveClass) {
        this.togglerTarget.classList.add(this.togglerActiveClass)
      }

      this.popper = createPopper(this.togglerTarget, this.contentTarget, {
        placement: this.data.get("placement"),
        modifiers: [
          {
            name: 'offset',
            options: { offset: [0, 6] },
          },
        ],
      })
    } else if (this.hasTogglerActiveClass) {
      this.togglerTarget.classList.remove(this.togglerActiveClass)
    }
  }

  hide(event) {
    if (this.element.contains(event.target) === false) {
      this.forceHide()
    }
  }

  forceHide() {
    this._destroyPopper()
    this.contentTarget.classList.add(this.contentInactiveClass)

    if (this.hasTogglerActiveClass) {
      this.togglerTarget.classList.remove(this.togglerActiveClass)
    }
  }

  _destroyPopper() {
    if (this.popper) {
      this.popper.destroy()
      this.popper = null
    }
  }
}
