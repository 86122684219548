import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["connectorSelect", "freshdeskUrlPrefix"]
  static classes = ["inactive"]

  connect() {
    this.updateFields()
  }

  updateFields() {
    this.freshdeskUrlPrefixTarget.classList.toggle(
      this.inactiveClass,
      this.connectorSelectTarget.value !== "freshdesk_xml_upload"
    )
  }
}
