import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "selectedValue"]

  connect() {
    this.update()
  }

  update() {
    const selectedValues = []

    for (const input of this.inputTargets) {
      if (input.checked) {
        const value = input.dataset.inputSelectValue
        selectedValues.push(value)
      }
    }

    if (selectedValues.length === 0) {
      this.selectedValueTarget.innerHTML = ''
    } else if (selectedValues.length === 1) {
      this.selectedValueTarget.innerHTML = selectedValues[0]
    } else {
      this.selectedValueTarget.innerHTML = selectedValues.length
    }
  }
}
